import $ from 'jquery';

class Animation {
  show(selector) {
    const element = $(selector);

    clearTimeout(element.attr('data-animation-id'));

    element.addClass('visible show').removeClass('hide');
  }

  hide(selector, time = 1000) {
    const element = $(selector);

    element.addClass('visible hide').removeClass('show');

    clearTimeout(element.attr('data-animation-id'));
    const timeoutId = setTimeout(() => {
      element.removeClass('visible show hide');
    }, time);

    element.attr('data-animation-id', timeoutId);
  }
}

export default new Animation();
