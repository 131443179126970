import $ from 'jquery';

// About mosaic
$('.about .column').each(function() {
	const has = $(this).find('.image-container').hasClass('big');
	if(has) {
		$(this).addClass('bigger')
	}
});


