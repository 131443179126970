import $ from 'jquery';
import Animation from './animation';

function bindEvents(){
  $('#start-quizz').on('click', function(evt){
    evt.preventDefault();
    Animation.hide('.quizz__intro');
    Animation.show('.quizz__list');
    startQuizz();
  })

  $('.btn-close-quizz').on('click', function(evt){
    evt.preventDefault();
    Animation.hide('.quizz__list');
    Animation.show('.quizz__intro');
    resetQuizz();
  })

  $('.quizz-response').on('change', function(){
    var _this = $(this);
    validationQueries(_this)
  })

  $('.next-question').on('click', function(evt){
    nextQuestion($(this));
  })

  $('.back-question').on('click', function(evt){
    prevQuestion($(this));
  })
}

function startQuizz(){
  $('.quizz__list__item[data-query="1"]').attr('data-hide', false);
  $('#query-01').addClass('active');
  animateQuestion($('.quizz__list__item[data-query="1"]'))
}

function validationQueries(obj){
  let choose = obj.attr('value');
  let answer = obj.parents('.quizz__question').data('response');
  let container = obj.parents('.quizz__list__item');

  container.addClass('show-response');

  if( choose == answer ){
    container.addClass('resp-true');
    return 
  }
  container.addClass('resp-false');
}

function resetQuizz(){
  $('.quizz__list__nav li:not("#query-01")').removeClass('active');
  $('.quizz__list__item').attr('data-hide', true);
  $('.quizz__list__item').removeClass('show-response resp-true resp-false visible')
  $('.quizz-response').prop('checked', false);
}

function nextQuestion(currentObj){
  var parentContainer = currentObj.parents('.quizz__list__item');
  var mountNext = parentContainer.data('query') + 1;
  var nextElement = $(`.quizz__list__item[data-query="${mountNext}"]`)

  if( !nextElement.length ){
    return 
  }

  $('.quizz__list__item').removeClass('show-response resp-true resp-false');
  $('.quizz__list__nav li').removeClass('active');
  parentContainer.attr('data-hide', 'true');

  nextElement.attr('data-hide', false);
  $(`#query-0${mountNext}`).addClass('active');

  animateQuestion(nextElement);
}

function prevQuestion(currentObj){
  var parentContainer = currentObj.parents('.quizz__list__item');
  var mountNext = parentContainer.data('query') - 1;
  var prevElement = $(`.quizz__list__item[data-query="${mountNext}"]`)

  if( mountNext == 0 ){
    Animation.hide('.quizz__list');
    Animation.show('.quizz__intro');
    resetQuizz();
    return 
  }

  $('.quizz__list__item').removeClass('show-response resp-true resp-false');
  $('.quizz__list__nav li').removeClass('active');
  parentContainer.attr('data-hide', 'true');

  prevElement.attr('data-hide', false);
  $(`#query-0${mountNext}`).addClass('active');

  animateQuestion(prevElement);
}

function animateQuestion(parent){
  var elemTitle = parent.find('.quizz__question__title');
  var elemText = elemTitle.text();
  var i = 0;
  var speed = 50;

  elemTitle.text('');

  while( i <  elemText.length ){
    (function(i){
      setTimeout(function(){
        elemTitle.append(elemText.charAt(i));
        if( i == (elemText.length - 1) ){
          parent.addClass('visible');
        } 
      }, speed * i)
    })(i++)
  }
}

;(function(){
  $('.quizz__intro').addClass('visible');
  bindEvents();
})();
