import $ from 'jquery';
import Animation from './animation';

const TIMER = 10000;

$('.highlight-carousel').each((index, element) => {

  const container = $(element);
  const slides = container.find('.slide');
  const buttonPrev = container.find('.button-next');
  const buttonNext = container.find('.button-prev');
  const dots = container.find('.dots button');
  const timerBar = container.find('.timer-bar > span');
  const imageTransition = container.find('.img-transition');

  let currentSlide = 0;
  let timeToChangeSlide = Infinity;
  let isLocked = false;

  setup();

  function setup() {
    buttonPrev.on('click', onClickNext);
    buttonNext.on('click', onClickPrev);
    dots.on('click', onClickDot);

    changeSlide(0);

    if (slides.length > 1) {
      window.requestAnimationFrame(updateTimer);
    } else {
      buttonPrev.remove();
      buttonNext.remove();
      dots.remove();
    }
  }

  function updateTimer(time) {
    if (time > timeToChangeSlide) {
      changeSlide(currentSlide + 1);
    }
    
    const percentTimeMissing = (timeToChangeSlide - time) / TIMER;
    const width = `${(1 - percentTimeMissing) * 100}%`;
    timerBar.css({ width });

    window.requestAnimationFrame(updateTimer);
  }

  function resetTimer(time) {
    if (!time) {
      time = window.performance.now();
    }
    timeToChangeSlide = time + TIMER;
  }

  function onClickNext() {
    changeSlide(currentSlide + 1);
  }

  function onClickPrev() {
    changeSlide(currentSlide - 1);
  }

  function onClickDot(e) {
    const index = $(e.currentTarget).index();
    changeSlide(index);
  }

  function changeSlide(newSlide) {
    // impede a troca se estiver numa transição
    if (isLocked) {
      return;
    }
    isLocked = true;

    // reinicia o timer para não haver trocas simultaneas
    resetTimer();

    // loop se estiver no primeiro ou último slide
    if (newSlide < 0) {
      newSlide = slides.length - 1;
    }
    if (newSlide > slides.length - 1) {
      newSlide = 0;
    }

    // troca a visibilidade dos slides
    Animation.hide(slides.eq(currentSlide));
    Animation.show(slides.eq(newSlide));

    // troca o dot acesso
    Animation.hide(dots.eq(currentSlide));
    Animation.show(dots.eq(newSlide));

    // troca as imagens com a transição especial
    const imgSlot = slides.eq(newSlide).find('.img');
    const image = imgSlot.attr('data-image');
    imageTransition.css({ height: `${imgSlot.innerHeight() * 1.1}px` });
    // displacement.changeTo(image);

    // preserva o novo slide para futuras trocas
    currentSlide = newSlide;

    // libera novas trocas somente depois de um tempo para dar tempo de terminar a animação
    setTimeout(unlock, 1300);
  }

  function unlock() {
    isLocked = false;
  }

});
