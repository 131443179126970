import $ from 'jquery';

// On click image play iframe
$('.video:not(.video-frame):not(.video-modal) .thumb').each(function(){

	$(this).on('click', function() {
	const $container = $(this).parent();
	const $iframe = $container.find('iframe');
	$container.addClass('video-play')
	$iframe.attr('allow', 'autoplay').attr('src', $iframe.attr('src') + "?autoplay=1&muted=1")
	});
	
});