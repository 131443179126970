import $ from 'jquery';
import Animation from './animation';

// Open menu
$('.header .btn-open-menu').on('click', function() {
	var menuOpened = $('.header.visible.show').length

	if( menuOpened ){
		Animation.hide('.menu-modal');
		Animation.hide('.menu .label.show');
		Animation.hide('.header');
		Animation.hide('.search-modal.visible.show');

		$('.open-close').removeClass('close');
		$('.submenu-mobile').removeClass('open');
		$('.submenu .sub').removeClass('open');

		return;
	}

	Animation.show('.menu-modal');
	Animation.show('.header');

	$('.submenu-mobile').removeClass('open');
});

// Desktop mouseover and show submenu
$('.menu a').on('mouseenter', function(){
	$('.menu a').removeClass('hovered');
	$(this).addClass('hovered');

	const val = $(this).attr('value');

	$(`.submenu .sub`).removeClass('open');
	$(`.submenu div[value=${val}]`).addClass('open');

	Animation.hide(`.menu .label.show`);
	Animation.show(`.menu .label[value=${val}]`);
});

// Mobile open and close button
$('.open-close').click(function() {

	const val = $(this).attr('value');
	const has = $(this).hasClass('close');

	// button change
	$('.open-close').removeClass('close');
	$(this).addClass('close');

	// submenu open
	$(`.submenu-mobile`).removeClass('open');
	$(`.submenu-mobile[value=${val}]`).addClass('open');

	// verify if has classe close
	if (has) {
		$('.open-close').removeClass('close');
		$(`.submenu-mobile[value=${val}]`).removeClass('open');
	}
});
