import $ from 'jquery';
import './scss/style.scss';
import 'slick-carousel/slick/slick.css';

// Components
import './js/components/menu.js';
import './js/components/related.js';
import './js/components/gallery.js';
import './js/components/countdown';
import './js/components/highlight-carousel';
import './js/components/header';
import './js/components/quizz.js';
import './js/components/accessibility.js';

import './js/components/about.js';
import './js/components/search.js';
import './js/components/share.js';
import './js/components/video.js';

// Pages
import './js/pages/history.js';
import './js/pages/instituto.js';
import './js/pages/agenda.js';
import './js/pages/reconhecimento.js';

const bg = [
	'background: #1AFF8B',
  'color: #ff6125',
  'padding: 10px 20px',
  'line-height: 25px',
  'font-size: 12px',
].join(';');

console.log('%cCoded by grama.cc', bg);

const isLanding = $('section').hasClass('landing');
const bannerHas = $('.landing__hero .banner').hasClass('banner--full');

if(isLanding && bannerHas) {
	$('body').addClass('landingBody');
}

$('.landing__form form').submit(function() {
	var file = $('.form__file').attr('href');
	window.open(file, '_blank');
});











