import $ from 'jquery';

	// Open and close more about
	const textContainer = $('.more-about .text-container');

	$('.more-about .more').click(function() {
		var has = $('.more-about .text-container').hasClass('open');

		textContainer.addClass('open');
		$(this).text("Ler menos");

		if(has) {
			textContainer.removeClass('open');
			$(this).text("Ler mais");
		}
	});

	const length = $('.numbers-container li').length;
	$('.numbers-container').addClass(`length-${length}`);

	// Open and close acorddion
	$('.accordion li h2, .accordion li h3').click(function() {
		var has = $(this).parent().hasClass('close');
		$('.accordion li').removeClass('close');
		$(this).parent().addClass('close');
		if(has) {
			$(this).parent().removeClass('close');
		}
	});
