import $ from 'jquery';

;(function(){
  var lastScroll = $(window).scrollTop();
  var containerHistory = $('.history-archive');

  if( $(window).scrollTop() > 90 && !$('.header').hasClass('show')){
    $('.header').addClass('floating');
  }

  $(window).scroll(function(){
    if( $('.header').hasClass('show') ){
      return
    }

    var nowScroll = $(window).scrollTop();
    var scrollTop = $(window).scrollTop();

    if( scrollTop < 90 ){
      $('.header').css('top', -scrollTop)
      $('.floating').removeClass('floating');
    } else {
      $('.header').addClass('floating');
      $('.header').css('top', '');
    }

    if( nowScroll < lastScroll && nowScroll > 80 ){
      $('body').addClass('header-is-floating');
    } else {
      $('body').removeClass('header-is-floating');
    }

    lastScroll = nowScroll;

    // Start animate history archive
    if( containerHistory && scrollTop > 625 ){
      $('body').addClass('history-posts-visible');
    }

  })

  if( containerHistory && lastScroll > 600 ){
    $('body').addClass('history-posts-visible');
  }

})();