import moment from 'moment';
import $ from 'jquery';
import datepickerFactory from 'jquery-datepicker';
// import datepickerBRFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-pt-BR';

const progressBars = $('.progress-bar .progress');

for(let i = 0; i < progressBars.length; i++ ){
  const progressBar = progressBars[i];

  const start = moment($(progressBar).attr('start'), 'DD/MM/YYYY, h:mm');
  const end = moment($(progressBar).attr('end'), 'DD/MM/YYYY, h:mm');
  const today = moment();
  const total = end.diff(start, 'days hour');
  const currentDays = today.diff(start, 'days hour');

  if(currentDays > total) {
    $(progressBar).css('width','100%');
  }else if(currentDays < 0) {
    $(progressBar).css('width','0');
  } else {
    $(progressBar).css('width',(currentDays/total*100)+'%');
  }
}

datepickerFactory($);

function getParams(key){
  var params = {};
  window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,
  function(str,key,value){
    params[key] = value
  });
  return key?params[key]:params;
}

var $year = getParams('date_year');
var $month = getParams('date_month');

$('.loading').css({ opacity: 0 }).css({ height: 0 });

const size = $('.events-container').find('.event-item').length;

if (size == 0) {
  $('.empty').css({ opacity: 1 }).css({ 'height': 'auto' });
  $('.empty h3').text('Não há eventos divulgados nesse mês. Aguarde');
} else {
  $('.empty').css({ opacity: 0 }).css({ height: 0 });
}

$.datepicker.regional['pt-BR'] = {
  prevText: '<',
  nextText: '>',
  monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto',
    'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ]
};

$.datepicker.setDefaults($.datepicker.regional['pt-BR']);

$('#datepicker').datepicker({
  defaultDate: new Date($year, $month - 1, 1),

  onChangeMonthYear: function (year, month) {
    window.location.reload();

    const d = new Date(year, month - 1);
    const mm = ("0" + (d.getMonth() + 1)).slice(-2);
    const yy = d.getFullYear();

    $('.empty').css({opacity: 0}).css({height: 0});
    $('.events-container .event-item').css({opacity: 0});
    $('.events-container').css({height: 0});
    $('.loading').css({opacity: 1}).css({height: 'auto'});

    $(this).css({pointerEvents: 'none'});
    history.pushState({}, '', '?date_year='+yy+'&date_month='+ mm);
  }
});

$('#agenda .view').click(function(){
  var has_open = $('.dropdown').hasClass('open');
  $('#agenda .dropdown').addClass('open');
  if(has_open ){
    $('#agenda .dropdown').removeClass('open');
  }
})

// Filter
$('#agenda form .item').click(function() {
  var val = $(this).attr('value');
  var name = $(this).attr('name');
  var has = $('.event-item').hasClass(val);

  $('.dropdown').removeClass('open');

  $('#agenda form label').removeClass('checked');
  $(this).addClass('checked');

  $('#agenda form label.view').text(name);

  $(`.event-item, .event-divisor`).css({display: 'flex'});
   $(`.event-item, .event-divisor`).removeClass('hide');

  $(`.event-item:not(.${val}), .event-divisor:not(.${val})`).hide();

  $(`.event-item:not(.${val}), .event-divisor:not(.${val})`).addClass('hide');

  $('.alert').remove();

  if(!has) {
    $('.events').append(`<h3 class="alert">Não encontramos nenhum resultado com o filtro <b>${name}</b></h3>`)
  }

  $( "#agenda .event-divisor.past:not(.noItem)" ).each(function( index ) {
  
    if(index == 0) {
      if ($(this).hasClass('hide')) {
        console.log('hide')
        $('.ja').hide();
      } else {
        $('.ja').show();
      }
    }

  });

})

$( "#agenda .event-divisor.past:not(.noItem)" ).each(function( index ) {
  
  if(index == 0) {
    $(this).before('<h3 class="ja">já aconteceu!</h3>');

    if ($(this).hasClass('hide')) {
      console.log('hide')
    }
  }

});


