import $ from 'jquery';
import slick from 'slick-carousel';

;(function(){
	// Carrossel default
	$('.carrossel').slick({
	  slidesToShow: 4.4,
	  slidesToScroll: 4,
	  infinite: false,
	  speed: 600,
	  cssEase: 'ease-in-out',

	  prevArrow: $('.prev'),
	  nextArrow: $('.next'),

	  responsive: [
	    {
	      breakpoint: 1000,
	      settings: {
	        slidesToShow: 2.2,
		  		slidesToScroll: 2,
	      }
	    },
	    {
	      breakpoint: 700,
	      settings: {
	        slidesToShow: 1.1,
		  		slidesToScroll: 1,
	      }
	    }
    ]
	});

	$('.carrossel-timeline').slick({
	  slidesToShow: 3.4,
	  slidesToScroll: 3,
	  infinite: false,
	  speed: 600,
	  cssEase: 'ease-in-out',

	  prevArrow: $('.prev-timeline'),
	  nextArrow: $('.next-timeline'),

	  responsive: [
	    {
	      breakpoint: 1000,
	      settings: {
	        slidesToShow: 2.2,
		  		slidesToScroll: 1,
	      }
	    },
	    {
	      breakpoint: 700,
	      settings: {
	        slidesToShow: 1.1,
		  		slidesToScroll: 1,
	      }
	    }
    ]
	});

	$('.carrossel-programs').slick({
	  slidesToShow: 3.4,
	  slidesToScroll: 1,
	  infinite: false,
	  speed: 600,
	  cssEase: 'ease-in-out',

	  prevArrow: $('.prev-programs'),
	  nextArrow: $('.next-programs'),

	  responsive: [
	  	{
	      breakpoint: 2000,
	      settings: {
	        slidesToShow: 4,
		  		slidesToScroll: 1,
	      }
	    },
	    {
	      breakpoint: 1800,
	      settings: {
	        slidesToShow: 3.4,
		  		slidesToScroll: 1,
	      }
	    },
	    {
	      breakpoint: 1000,
	      settings: {
	        slidesToShow: 2.2,
		  		slidesToScroll: 1,
	      }
	    },
	    {
	      breakpoint: 700,
	      settings: {
	        slidesToShow: 1.1,
		  		slidesToScroll: 1,
	      }
	    }
    ]
	});

	$('.carrossel-pages').slick({
	  slidesToShow: 3.4,
	  slidesToScroll: 1,
	  infinite: false,
	  speed: 600,
	  cssEase: 'ease-in-out',
	  arrow: false,

	  responsive: [
	  	{
	      breakpoint: 2000,
	      settings: {
	        slidesToShow: 3.4,
		  		slidesToScroll: 1,
	      }
	    },
	    {
	      breakpoint: 1800,
	      // settings: "unslick"
	      settings: {
	        slidesToShow: 3.4,
		  		slidesToScroll: 1,
	      }
	    },
	    {
	      breakpoint: 1000,
	      settings: {
	        slidesToShow: 2.2,
		  		slidesToScroll: 1,
	      }
	    },
	    {
	      breakpoint: 700,
	      settings: {
	        slidesToShow: 1.1,
		  		slidesToScroll: 1,
	      }
	    }
    ]
	});

	$('.carrossel-internal').slick({
	  slidesToShow: 4.2,
	  slidesToScroll: 1,
	  infinite: false,
	  speed: 600,
	  cssEase: 'ease-in-out',
	  arrow: false,

	  responsive: [
	    {
	      breakpoint: 1000,
	      settings: {
	        slidesToShow: 2.2,
		  		slidesToScroll: 1,
	      }
	    },
	    {
	      breakpoint: 700,
	      settings: {
	        slidesToShow: 1.1,
		  		slidesToScroll: 1,
	      }
	    }
    ]
	});

	$('.carrossel-attachments').slick({
	  slidesToShow: 3,
	  slidesToScroll: 1,
	  infinite: false,
	  speed: 600,
	  cssEase: 'ease-in-out',
	  arrow: false,

	  responsive: [
	    {
	      breakpoint: 1000,
	      settings: {
	        slidesToShow: 2.2,
		  		slidesToScroll: 1,
	      }
	    },
	    {
	      breakpoint: 700,
	      settings: {
	        slidesToShow: 1.1,
		  		slidesToScroll: 1,
	      }
	    }
    ]
	});
})();



