import $ from 'jquery';

// Load more list itens
const list = $('#recognitions .recognitions__topics');
const itens = $('#recognitions .topics__item');
const btn = $('#recognitions .load-more');
const length = itens.length;
const first_item = $('#recognitions .topics__item:first-child');


const hash = window.location.hash;
let clicks = 1;

function scrollToElement(ele) {
  $('html, body').stop().animate({ scrollTop: ele.offsetTop}, 500);
}

function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
}


if(window.location.hash) {
	let dataItem = $(hash).attr('data-item');
	let i;
	for (i = 1; i <= dataItem; i++) { 
	  $(`#item_${i}`).addClass('open_more');
	}

	clicks = dataItem;
}

// Load more itens
btn.click(function(){ 
	clicks++; 

	const current_item = $('#recognitions .topics__item:nth-child('+ clicks + ')');
	const id = current_item.attr('id');

	current_item.addClass('open_more');

	$(this).attr('href', '#'+id);

	const href = '#'+id;
	const size = '#item_'+length;

	if(href === size) {
		$('.recognitions__topics').addClass('open');
	}
});


function getParams(key){
  var params = {};
  window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,
  function(str,key,value){
    params[key] = value
  });
  return key?params[key]:params;
}


const filter = getParams('filter');
const year = getParams('year');
let array = [];

if(filter){
  array = filter.split(',');
  $('#recognitions .checkbox-container').find('.fake-checkbox').removeClass('active');
  $('#recognitions .checkbox-container').removeClass('checked');

  array.map(function(c, i) {
  	$(`:not(.${c})`).addClass('hide');
  	$(`.${c}`).addClass('show');
  	$(`label[for=${c}]`).addClass('active');
  });
}

$('#recognitions .checkbox-container').on('click', function(e){
  e.preventDefault();
  const category = $(this).find('input').attr('value');
  const has = $(this).hasClass('active');

  var url = window.location.search;

  if (year) {
  	console.log('tem ano')
  }
  // Check item
  $(this).find('.fake-checkbox').addClass('active');
  $(this).addClass('checked');

  if(category != 'todas'){
    $('input[value="todas"]').parent().find('.fake-checkbox').removeClass('active');
    array.push( category );
    history.pushState( {}, '', '?filter=' + array );

    if (year) {
    	 history.pushState( {}, '', `?year=${year}&filter=${array}`);
    }
  }
  
  if(has) {
    array = $.grep(array, function(value) {
      return value != category;
    });
    history.pushState( {}, '', '?filter=' + array );

    if (year) {
    	 history.pushState( {}, '', `?year=${year}&filter=${array}`);
    }

    // Check item
    $(this).find('.fake-checkbox').removeClass('active');
    $(this).removeClass('checked');
  }
 
  // Reload
  location.reload();
});



////////

$('#recognitions label.btn-filter').on('click', function(e){
  e.preventDefault();

  const has = $(this).parent().find('.dropdown').hasClass('open');

  $(this).parent().find('.dropdown').addClass('open');
  $(this).find('.icon').addClass('open');

  if (has) {
  	$(this).parent().find('.dropdown').removeClass('open');
  	$(this).find('.icon').removeClass('open');
  }
});


const firstYear = $('#recognitions .topics__item:first-child').attr('year');
if (year) {
	const yearNumber = $(`li[year="${year}"]`).attr('data-filter-year');
	$('#recognitions .topics__item').removeClass('open_more');
	$('#recognitions .topics__item').hide();
	$(`li[year="${year}"]`).addClass('open_more');
	$(`li[year="${year}"]`).show();
	$('.load-more').hide();
	$('#recognitions .recognitions__topics').addClass('year-filter');
	$('label[data-filter="filter-by-year"]').find('.text').text(yearNumber);

	if (year != firstYear) {
  	$('#recognitions .topics__item:first-child').hide();
  }

  if(year == 'all') {
		$('#recognitions .topics__item').removeClass('open_more');
		$('#recognitions .topics__item').show();
		$('.load-more').show();
		$('#recognitions .recognitions__topics').removeClass('year-filter');
		$('label[data-filter="filter-by-year"]').find('.text').text('Filtrar por ano');
  }
}

$('#recognitions .dropdown__container .filter-opt').on('click', function(e){
		const year = $(this).attr('data-filter');

		const yearNumber = $(this).attr('data-filter-number');

    history.pushState( {}, '', `?year=${year}` );

    if (filter) {
    	history.pushState( {}, '', `?year=${year}&filter=${filter}`);
    }

    $('label[data-filter="filter-by-year"]').find('.text').text(yearNumber);
    $('.load-more').hide();
    $('#recognitions .recognitions__topics').addClass('year-filter');
    $('#recognitions .topics__item').removeClass('open_more');
    $(`li[year="${year}"]`).addClass('open_more');

    if (year != firstYear) {
    	$('#recognitions .topics__item:first-child').hide();
    }
    if(year == 'all') {
			$('label[data-filter="filter-by-year"]').find('.text').text('Filtrar por Ano');
			history.pushState( {}, '', ' ' );
			if (year) {
				history.pushState( {}, '', `?filter=${filter}`);
			}
			$('#recognitions .topics__item').removeClass('open_more');
			$('#recognitions .topics__item:first-child').show();

			$('.load-more').show();
			$('#recognitions .recognitions__topics').removeClass('year-filter');
    }
  // Reload
  location.reload();
});

