import $ from 'jquery';
import slick from 'slick-carousel';

;(function(){
  const gallery = $('.gallery');
  const gallerySlide = $('.gallery-slide');

  if( gallery ){
    if(window.innerWidth < 1000 ){
      // Carrossel default
      $('.gallery').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 600,
        cssEase: 'ease-in-out',
        centerMode: true,
        centerPadding: '35px',
        arrows: false
      });
    }
  }

  if( gallerySlide ){
    $('.gallery-slide').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      speed: 600,
      cssEase: 'ease-in-out',
      centerMode: true,
      centerPadding: '227px',
      responsive: [{
        breakpoint: 1024,
        settings: {
          arrows: false,
          centerPadding: '35px'
        }
      }]
    });
  }

  // Open modal mosaic
  $('.mosaic').on('click', '.gallery__btn', function(){
    $(this).parent().find('img').clone().appendTo('.modal-content');
    $('body').addClass('modal-visible')
  })

  $('body').on('click', '.modal', function(evt){
    $('body').removeClass('modal-visible');
    $('.modal .modal-content').html('');
  })

  /*
    Add evento videos
  */

  // video on modal
  $('.video-frame:not(.full), .video-modal').on('click', function(){
    var videoURL = $(this).data('video').split('v=')[1]
    var videoIframeURL = !videoURL ? $(this).data('video') : 'http://www.youtube.com/embed/'+videoURL;
    var videoIframeHTML = '<iframe width="720" height="405" src="'+ videoIframeURL +'?autoplay=1&controls=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
    $('.modal .modal-content').html(videoIframeHTML);
    $('body').addClass('modal-visible');
  })

  // video full
  var videoFull = $('.video-frame.full')

  if(videoFull){
    var videoIframeHTML;
    videoFull.each(function(){
      var video = $(this);
      var videoURL = video.data('video').split('v=')[1];
      var videoIframeURL = 'http://www.youtube.com/embed/'+videoURL;
      videoIframeHTML = '<iframe width="720" height="405" src="'+ videoIframeURL +'?enablejsapi=1&controls=0&autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
    })

    $(videoFull).on('click', function(evt){
      var $that = $(this);
      var $target = evt.target;
      if($that.hasClass('video-play')) {
        return ;
      }
      var $iframe = $that.find('.content-video')
      $iframe.append(videoIframeHTML);
      $that.addClass('video-play');
    })
  }
})();