import $ from 'jquery';
import Animation from './animation';

// Search
$('.search-button-open').on('click', function(){
	Animation.show('.search-modal');
	Animation.show('.header');
});

$('.search-button-close').on('click', function(){
	Animation.hide('.search-modal');
	Animation.hide('.header');
});

// Filter check and uncheck
$('.search-modal label').click(function() {
	const field = $(this).find('input');
	const fake = $(this).find('.fake-checkbox');
	const id = field.attr('id');
	const val = field.val();

	if( val == 'todas' ){
		$('.checkbox-container').find('.fake-checkbox').removeClass('clicked');
		fake.addClass('clicked');
		return 
	}

	$('.checkbox-container[type="todas"] .fake-checkbox').removeClass('clicked');
	fake.toggleClass('clicked');
	$('#cat').attr('value', id);
});

$('#searchform input[type="text"]').val("");

$('.scroll').on('click', function(evt){
	evt.preventDefault();

	const parent = $(this).parents('.events_container');
	const nextElemScroll = parent.next();

	$("html, body").animate({ scrollTop: nextElemScroll.offset().top }, 1000);

})


