import $ from 'jquery';

// Show share
$('.share-btn').click(function(){
	const has = $(this).find('.share-container').find('a').hasClass('show');
	
	if(has) {
		$(this).find('.share-container').find('a').removeClass('show');
		$(this).removeClass('hide');
	} else {
		$(this).find('.share-container').find('a').addClass('show');
		$(this).addClass('hide');
	}
});


