import $ from 'jquery';

function changeFonts(newValue){
  const root = $(':root')
  root.css('font-size', newValue);
  $('.control-button').prop('disabled', false);
  localStorage.setItem('font-accessibility', newValue);
}

function removeFontsChanged(){
  $('html').css('font-size', '');
  $('.control-button.smaller').prop('disabled', true);
  localStorage.removeItem('font-accessibility');
}

function checkFontsChanged(saveFonts){
  if( saveFonts ){
    var newFontSize = Number(saveFonts);
    $('html').css('font-size', newFontSize)
    $('.control-button.smaller').prop('disabled', false);
    if( newFontSize == 20 ){
      $('.control-button.bigger').prop('disabled', true);
    }
  }
}

function shortcutNavigation(keySet){
  if ( keySet.altKey  &&  keySet.key == "1" || keySet.keyCode == "49" ) {
    $('html, body').animate({
      scrollTop: $('#wrap').offset().top
    }, 2000);
  } else if ( keySet.altKey  &&  keySet.key == "2" || keySet.keyCode == "50" ) {
    $('.btn-open-menu').trigger('click');
  } else if ( keySet.altKey  &&  keySet.key == "3" || keySet.keyCode == "51" ) {
    $('.search-button-open').trigger('click');
    $('header').removeClass('floating');
  }
}

;(function(){
  const savedFonts = localStorage.getItem('font-accessibility');
  checkFontsChanged(savedFonts);
   
  $('.acessibility .control-button').on('click', function(evt){
    evt.preventDefault();
    const $that = $(this);
    const valueDefault = new Number( $(':root').css('font-size').replace(/px/,""))
    const number = parseInt(valueDefault);
    const numberMinus = parseInt(valueDefault - 1);

    if( $that.hasClass('bigger') ){
      if( number == 19 ){
        changeFonts(valueDefault + 1);
        $('.control-button.bigger').prop('disabled', true);
        return
      } 
      changeFonts(valueDefault + 1)
        return
    }
    if(numberMinus == 16){
      removeFontsChanged();
      return
    }
    changeFonts(valueDefault - 1);
   })

   $(window).on('keydown', function(e){
    const hasInputFocus = $('input').is(':focus');
    if( hasInputFocus ){
      return
    }
    shortcutNavigation(e);
   })
})();
