import $ from 'jquery';

  // Open and close filter
  $('.open-filter-mobile').on('click', function(){
    var $parent = $(this).parents('.history-filter');
    var has = $parent.hasClass('open');
    $parent.addClass('open');

    if(has) {
      $parent.removeClass('open');
    }
  });

  // Toggle share mobile
  $('.share .label-mobile').on('click', function(evt){
    evt.preventDefault();
    var $that = $(this);
    $that.parent().toggleClass('visible');
  })

  // Scroll and fix side social bar
  var has = $('section').hasClass('post');
  if (has) {
    $(window).scroll(function(){
      const elPosition = $( '.post-container' ).offset().top;
      const containerHeight = $( '.post-container' ).height();
      const elHeight = $( '.share' ).height();
      const windowPosition = $( this ).scrollTop();
      const elLimit = containerHeight - elHeight + elPosition - 100; // 20 - element top

      if ( windowPosition > elPosition && windowPosition < elLimit ) {
        $('.share').addClass('fixed');
        $('.share').removeClass('absolute');
      } 
      else if ( windowPosition >= elLimit ) {
        $('.share').addClass('absolute');
        $('.share').removeClass('fixed');
      } 
      else {
        $('.share').removeClass('fixed');
        $('.share').removeClass('absolute');
      }
    });
  }

  $.fn.almComplete = function(alm){
    $('.container-initial').hide();
    var initialPosts = $('.container-initial').html();

    $('#ajax-load-more > ul').prepend(initialPosts);
  };

  $.fn.almComplete();

  const historyContaner = $('.history-archive');

  if( historyContaner ){
    $(window).on('scroll', function(){
      var staticElem = $('.news').offset().top;
      var scrollWindow = $(window).scrollTop();
      var scrollStartLoad = scrollWindow + 800;
      if( scrollStartLoad >= staticElem ){
        $('.alm-load-more-btn').trigger('click');
      }
    })
  }

  function getParams(key){
    var params = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,
    function(str,key,value){
      params[key] = value
    });
    return key?params[key]:params;
  }

  const filter = getParams('filter');
  let array = [];

  if(filter){
    array = filter.split(',');
    $('.history-filter .checkbox-container').find('.fake-checkbox').removeClass('clicked');
    $('.checkbox-container').removeClass('checked');
    array.map(function(classe, i) { 
      $('.' + classe).find('.fake-checkbox').addClass('clicked');
      $('.' + classe).addClass('checked');
    });
  }
  
  $('.history-filter .checkbox-container').on('click', function(e){
    e.preventDefault();
    const category = $(this).find('input').attr('value');
    const has = $(this).find('.fake-checkbox').hasClass('clicked');

    // Check item
    $(this).find('.fake-checkbox').addClass('clicked');
    $(this).addClass('checked');

    if(category != 'todas'){
      $('input[value="todas"]').parent().find('.fake-checkbox').removeClass('clicked');
      array.push( category );
      history.pushState( {}, '', '?filter=' + array );
    }
    
    if(has) {
      array = $.grep(array, function(value) {
        return value != category;
      });
      history.pushState( {}, '', '?filter=' + array );

      // Check item
      $(this).find('.fake-checkbox').removeClass('clicked');
      $(this).removeClass('checked');
    }

    if (category == 'todas') {
      array = [];
      $('.fake-checkbox').removeClass('clicked');
      $(this).find('.fake-checkbox').addClass('clicked');
      history.pushState( {}, '', '?filter=' + array);
    }
   
    // Reload
    location.reload();
  });

