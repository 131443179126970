import $ from 'jquery';

;(function(){
  let countdown = $('.countdown');
  if( countdown ){
    var count = new Date(countdown.attr('data-cout') ).getTime();
    var timer;
    var second = 1000;
    var minute = second * 60;
    var hour = minute * 60;
    var day = hour * 24;

    function countdownDate() {
      var now = new Date().getTime();
      var timeCount = count - now ;

      if( timeCount < 0 ){
        clearInterval(timer);
        $('.countdown').prepend('<h3>Expirado!</h3>');
        return;
      }

      var days = Math.floor(timeCount / day);
      var hours = Math.floor((timeCount % day) / hour);
      var minutes = Math.floor((timeCount % hour) / minute);
      var seconds = Math.floor((timeCount % minute) / second);

      $('.countdown-day').text(days + '// ');
      $('.countdown-hour').text(hours + ':');
      $('.countdown-minutes').text(minutes + ':');
      $('.countdown-seconds').text(seconds);
    }
    timer = setInterval(countdownDate, 1000)
  }
})();